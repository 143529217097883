import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import logo from "../images/twitter-card-default.jpg";
import faqData from "../../content/faq/faq.json";

interface Props {
  title: string;
  titleTemplate?: string;
  titleSeparator?: string;
  description?: string;
  isArticle?: boolean;
  isFaq?: boolean;
  image?: string;
  siteLanguage?: string;
  siteLocale?: string;
  twitterUsername?: string;
  author?: string;
  datePublished?: string;
  dateModified?: string;
}

export const SEO: React.FC<Props> = (Props) => {
  const { pathname } = useLocation();
  const siteUrl = "https://fastrrr.com/";
  // ! default parameters can be added here
  const {
    title,
    titleTemplate = "Fastrrr - Science Behind Sailing",
    titleSeparator = "-",
    description = "Fastrrr is a unique and innovative analysis solution based on AI for Sailors and their Coaches",
    isArticle = false,
    isFaq = false,
    image = logo,
    siteLanguage = "en",
    siteLocale = "en_GB",
    author = "Fastrrr",
    twitterUsername,
    datePublished,
  } = Props;

  const seo = {
    title: title.slice(0, 70), // ! max 70 chars
    description: description.slice(0, 160), // ! max 160 chars
    datePublished: datePublished ? null : new Date(Date.now()).toISOString(),
  };

  const copyrightYear = new Date().getFullYear();

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // Structured Data Testing Tool >>
  // https://search.google.com/structured-data/testing-tool

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: siteUrl + pathname,
    headline: seo.description,
    inLanguage: siteLanguage ?? "en",
    mainEntityOfPage: siteUrl + pathname,
    description: seo.description,
    name: seo.title,
    author: {
      "@type": "Organization",
      name: author,
    },
    copyrightHolder: {
      "@type": "Organization",
      name: author,
    },
    copyrightYear,
    creator: {
      "@type": "Organization",
      name: author,
    },
    publisher: {
      "@type": "Organization",
      name: author,
    },
    datePublished: seo.datePublished,
    image: {
      "@type": "ImageObject",
      url: `${logo}`,
    },
  };

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": siteUrl + pathname,
        name: "Homepage",
      },
      position: 1,
    },
  ];

  let schemaArticle = null;

  if (isArticle) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Organization",
        name: author,
      },
      copyrightHolder: {
        "@type": "Organization",
        name: author,
      },
      copyrightYear,
      creator: {
        "@type": "Organization",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          url: `${logo}`,
        },
      },
      datePublished: seo.datePublished,
      // dateModified: seo.dateModified,
      description: seo.description,
      headline: seo.title,
      inLanguage: siteLanguage ? siteLanguage : "en",
      url: siteUrl + pathname,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: siteUrl + image,
      },
      mainEntityOfPage: siteUrl + pathname,
    };

    // Push current blog post into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": siteUrl + pathname,
        name: seo.title,
      },
      position: 2,
    });
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  };

  return (
    <>
      <Helmet
        title={seo.title}
        titleTemplate={`%s ${titleSeparator ?? `·`} ${titleTemplate}`}
      >
        <html lang={siteLanguage ?? "en"} />
        <link rel="canonical" href={siteUrl + pathname} />
        <meta name="description" content={seo.description} />

        {!isArticle && !isFaq && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {isArticle && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        {isFaq && (
          <script type="application/ld+json">{JSON.stringify(faqData)}</script>
        )}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      {/* facebook & twitter */}
      {image && (
        <Helmet>
          <meta name="twitter:card" content={"summary_large_image"} />
          {twitterUsername && (
            <meta name="twitter:creator" content={twitterUsername} />
          )}
          <meta name="twitter:title" content={seo.title} />
          <meta name="twitter:description" content={seo.description} />
          <meta name="twitter:image" content={siteUrl + image} />
          <meta name="twitter:image:alt" content={seo.description} />

          <meta property="og:locale" content={siteLocale ?? "en_GB"} />
          <meta property="og:url" content={siteUrl + pathname} />
          <meta
            property="og:type"
            content={isArticle ? "article" : "website"}
          />
          <meta property="og:title" content={seo.title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={siteUrl + image} />
          <meta property="og:image:alt" content={seo.description} />
        </Helmet>
      )}
    </>
  );
};

export default SEO;
